<template>
  <vbt-content-box
    :loading="loading"
    :loading-error="loadingError"
  >
    <v-layout
      slot="title"
      class="warning--text subtitle-2"
    >
      <strong class="mr-1">
        Warning!
      </strong>
      Please note that changes will be applied only after site and service restart.
      Please contact IT support for that.
    </v-layout>

    <v-layout>
      <v-flex sm2>
        <v-text-field
          v-model="form.name"
          label="Name"
          disabled
        />
      </v-flex>

      <v-flex
        class="ml-10"
        sm2
      >
        <v-text-field
          v-model="form.routeSuffix"
          label="Route"
          disabled
        />
      </v-flex>
    </v-layout>

    <strong class="mt-3">
      ProFIT Settings
    </strong>

    <v-divider class="mt-1 mb-2" />

    <v-text-field
      v-model.trim="form.profitUrl"
      label="ProFIT URL"
    />

    <strong class="mt-3">
      Rabbit MQ Settings
    </strong>

    <v-divider class="mt-1 mb-6" />

    <v-layout align-center>
      <v-flex sm3>
        <v-text-field
          v-model.trim="form.rabbitMqHost"
          class="vbt-hide-form-control-details"
          label="Host"
        />
      </v-flex>

      <strong class="mx-2">
        :
      </strong>

      <v-flex sm2>
        <v-layout>
          <v-text-field
            v-model.trim.number="form.rabbitMqPort"
            class="vbt-hide-form-control-details"
            style="width: 75px;"
            label="Port"
          />

          <v-text-field
            v-model.trim="form.rabbitMqVirtualHost"
            class="vbt-hide-form-control-details ml-2"
            label="Virtual Host"
          />
        </v-layout>
      </v-flex>

      <v-spacer />

      <v-flex sm3>
        <v-text-field
          v-model.trim.number="form.rabbitMqUsername"
          class="vbt-hide-form-control-details"
          label="Login"
        />
      </v-flex>

      <v-flex
        sm3
        class="ml-3"
      >
        <dsb-password-input
          v-model="form.rabbitMqPassword"
          class="vbt-hide-form-control-details"
        />
      </v-flex>
    </v-layout>

    <v-layout
      align-center
      class="mt-7"
    >
      <v-flex sm4>
        <v-text-field
          v-model.trim="form.rabbitMqContentEncoding"
          class="vbt-hide-form-control-details"
          label="Encoding"
        />
      </v-flex>

      <v-checkbox
        v-model="form.rabbitMqPersistent"
        class="vbt-hide-form-control-details ml-5"
        label="Persistent"
      />
    </v-layout>

    <v-layout
      align-center
      class="mt-7"
    >
      <v-flex
        class="pr-2"
        sm2
      >
        <v-text-field
          v-model.trim="form.rabbitMqNewOrderRoute"
          label="New Queue"
        />
      </v-flex>

      <v-flex
        class="px-2"
        sm2
      >
        <v-text-field
          v-model.trim="form.rabbitMqUpdateOrderRoute"
          label="Update Queue"
        />
      </v-flex>

      <v-flex
        class="px-2"
        sm2
      >
        <v-text-field
          v-model.trim="form.rabbitMqUpdateOrderItemRoute"
          label="Update Item Queue"
        />
      </v-flex>

      <v-flex
        class="px-2"
        sm4
      >
        <v-text-field
          v-model.trim="form.rabbitMqUpdatePressSheetTemplateRoute"
          label="Update Press Sheet Template Route"
        />
      </v-flex>
    </v-layout>

    <strong class="mt-3">
      Digital Settings
    </strong>

    <v-divider class="mt-1 mb-2" />

    <v-layout align-center>
      <v-flex
        class="pr-2"
        sm2
      >
        <v-text-field
          v-model.trim.number="form.hoursToDisableOrders"
          label="Time To Timeout Orders (hours)"
          type="number"
        />
      </v-flex>

      <v-flex
        class="px-2"
        sm2
      >
        <v-text-field
          v-model.trim.number="form.messagesMaxLifeHours"
          label="Messages Max Life Time (hours)"
          type="number"
        />
      </v-flex>

      <v-flex
        class="px-2"
        sm2
      >
        <v-text-field
          v-model.trim.number="form.loadStatisticPeriodHours"
          label="Load Balancing Max Period (hours)"
          type="number"
        />
      </v-flex>
    </v-layout>

    <v-layout justify-space-between>
      <v-btn
        text
        small
        @click="resetForm"
      >
        Reset
      </v-btn>

      <v-btn
        small
        color="success"
        @click="updateLocation"
      >
        Save
      </v-btn>
    </v-layout>
  </vbt-content-box>
</template>

<script>
import { mapState, mapActions } from 'vuex';

import { useDataLoading } from '@mixins/factories';

import { wrapToLoadingFn } from '@helpers';

import { AppLocationsNamespace } from '@store/types';

import { createLocation } from '../_meta/location';

import { LocationsApiService } from '../_services/locations.api.service';

import {
  namespace as LocationsNamespace,
  ActionTypes as LocationsActionTypes,
} from '../store.types';

const dataLoadingOptions = {
  getterName: LocationsActionTypes.GET_LOCATION,
  parameterize: true,
  searchable: false,
  paged: false,
};

export default {
  name: 'DsbLocation',

  mixins: [
    useDataLoading(dataLoadingOptions),
  ],

  data() {
    return {
      form: createLocation(),
      showRabbitMqPassword: false,
    };
  },

  computed: {
    ...mapState(AppLocationsNamespace, ['suffix']),

    ...mapState(LocationsNamespace, {
      locationStr: ({ location: l }) => JSON.stringify(l),
    }),
  },

  watch: {
    locationStr: {
      handler(newValue) {
        Object.assign(this.form, JSON.parse(newValue));
      },
      immediate: true,
    },
  },

  methods: {
    ...mapActions(LocationsNamespace, [LocationsActionTypes.GET_LOCATION]),

    updateLocation() {
      this.$VBlackerTheme.alert.warning({
        text: 'Update location?',
      }, () => this.wrapToLoadingFn({
        req: LocationsApiService.updateLocation.bind({}, {
          suffix: this.$route.params.suffix,
          data: this.form,
        }),
        fullscreenLoadingSpinner: true,
        onSuccess: () => {
          this.$VBlackerTheme.notification.success('Location successfully updated');
          this.$_fetchData();
        },
      }));
    },

    resetForm() {
      Object.assign(this.form, JSON.parse(this.locationStr));
    },

    wrapToLoadingFn,
  },
};
</script>
